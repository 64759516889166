import axios from "axios";
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
import { Process } from "./@classes/process";
import { HandlingStorage } from "./@classes/handling-storage";
// @see https://github.com/vuejs/core/tree/main/packages/vue
const Vue = require("vue");
/**
 * 프로세스 인스턴스 생성
 * @param data 데이터
 * @param rules 유효검증규칙
 * @param messages 검증실패 메세지
 * @returns
 */
window.$process = (data, rules, messages) => {
    return new Process(data, rules, messages);
};
window.$storage = new HandlingStorage();
// 폼데이터 추출
window.$formData = (selectorOrElement, appends) => {
    const element = typeof selectorOrElement === "string"
        ? document.querySelector(selectorOrElement)
        : selectorOrElement;
    return window.Skript.extractFormData(element, appends || {}, (formData, elName, files, element) => {
        // ui input file 대응
        const fileMMObject = window.mm.data.get(element, "file");
        const customResult = files && files[0] ? files[0].result : null;
        if ((fileMMObject?.file?.result &&
            fileMMObject.file?.view?.result) ||
            customResult) {
            /**
             * 고정사이즈인 경우 추출 경로가 다름
             */
            const dataURI = (element
                .getAttribute("data-file")
                ?.match(/'_imageRatio':\s?'[0-9]*\/[0-9]*'/)
                ? fileMMObject?.file?.view?.result
                : fileMMObject?.file?.result) ||
                customResult ||
                "";
            const filename = fileMMObject?.file?.name || (files ? files[0].name : "");
            /**
             * dataURI -> 바이너리 데이터 컨버팅
             */
            const parts = dataURI.split(",");
            const match = parts[0].match(/:(.*?);/);
            if (!match) {
                return;
            }
            const mime = match[1];
            const byteString = window.atob(parts[1]);
            const byteLength = byteString.length;
            const u8array = new Uint8Array(byteLength);
            for (let index = 0; index < byteLength; index++) {
                u8array[index] = byteString.charCodeAt(index);
            }
            formData.append(elName, new Blob([u8array], { type: mime }), filename);
            return;
        }
        if (!files || files.length < 1) {
            return;
        }
        if (files.length > 1) {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                formData.append(`${elName}[${index}]`, file);
            }
        }
        else {
            formData.append(elName, files[0]);
        }
    });
};
/**
 * 공용 에러 핸들러
 * @param err 에러
 * @returns
 */
window.$catch = (err) => {
    const httpMessages = {
        400: "잘못된 요청입니다.",
        404: "요청 대상을 찾을 수 없습니다.",
        500: "서버 에러가 발생했습니다.",
    };
    return new Promise((resolve, reject) => {
        console.error(err);
        if (err.response) {
            // ajax 오류 응답의 경우
            window.mm.bom.alert(err.response.data?.message || httpMessages[err.response.status], () => resolve(err));
        }
        else if (err.firstErrorMessage) {
            // validate 실패 오류의 경우
            window.mm.bom.alert(err.firstErrorMessage, () => resolve(err));
        }
        else if (err.message) {
            // js Error 객체인 경우
            window.mm.bom.alert(err.message, () => resolve(err));
        }
        else {
            // 여긴 오면 안 됨..
            window.mm.bom.alert("시스템 오류가 발생했습니다.", () => resolve(err));
        }
    });
};
/*
|--------------------------------------------------------------------------
| 브라우저 컨텍스트 사용 라이브러리
|--------------------------------------------------------------------------
|
| 브라우저에서 직접 호출 필요한 라이브러리 등록
|
*/
// 유효성 검사기
// @see https://www.npmjs.com/package/@owneul/kalidator
import Kalidator from "@owneul/kalidator";
/**
 * 전역 테스터 등록 샘플
 * @example
 * const data = { '철수': 3, '영희': 1, }
 * const rules = { '철수': ['가위낸사람수맞추기:✌🏽,✋🏽,✋🏽,✋🏽'], '영희': ['가위낸사람수맞추기:✌🏽,✋🏽,✋🏽,✋🏽'] }
 */
Kalidator.registGlobalTester("가위낸사람수맞추기", (name, extraValues, dataBag) => {
    // 가위를 낸 사람을 예상한 수
    const betCount /** 철수는 3, 영희는 1 */ = Number.parseInt(Kalidator.getTargetValue(dataBag, name));
    // 실제로 가위 낸 사람 수
    const scissorCount = extraValues.filter((choice) => choice === "✌🏽").length;
    // 정확히 맞추면 통과(철수는 실패, 영희는 성공)
    return betCount === scissorCount;
});
/**
 * 전역 메세지 등록 샘플
 */
Kalidator.setGlobalMessage("가위낸사람수맞추기", ":param(은/는) 틀려먹었습니다.");
Kalidator.setGlobalMessage("required", ":param(을/를) 입력해주세요");
Kalidator.setGlobalMessage("requiredIf", ":param(을/를) 입력해주세요");
/*
|--------------------------------------------------------------------------
| 커스텀 테스트 등록
|--------------------------------------------------------------------------
|
| 글로벌 유효성 검사 규칙 적용
|
*/
Kalidator.registGlobalTester("requiredIn", (name, extraValues, dataBag) => {
    const array = Kalidator.getTargetValue(dataBag, extraValues[0]);
    if (!array.includes(extraValues[1])) {
        return true;
    }
    const value = Kalidator.getTargetValue(dataBag, name);
    return value !== undefined && value !== null && value !== "";
});
Kalidator.setGlobalMessage("requiredIn", ":param(을/를) 입력해주세요");
window.Kalidator = Kalidator;
// 유틸 인스턴스
// @see https://github.com/kei155/Skript.js/blob/HEAD/docs/classes/_skript_.skript.md
import Skript from "@owneul/skript";
window.Skript = new Skript();
// 날짜 관련 헬퍼 라이브러리
// @see https://momentjs.com/docs/
import moment from "moment";
window.moment = moment;
// 뷰 인스턴스 생성 랩핑함수
window.$makeVue = (selector, vueOptions) => {
    const app = Vue.createApp({
        ...vueOptions,
        // 전체 뷰 인스턴스 적용 믹스인
        mixins: [],
    });
    // vue 작동내 오류 처리
    app.config.errorHandler = (err, vm, info) => {
        // handle error
        if (process.env.NODE_ENV !== "production") {
            console.error(vm, info);
        }
        window.$catcher(err);
    };
    // 전역사용 프로퍼티
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.$format = {
        /**
         * 날짜형식 포맷팅
         * @param val
         * @param format
         * @returns
         */
        date(val, format) {
            return moment(val).format(format);
        },
        /**
         * 숫자 포맷팅(3자리씩 콤마처리)
         * @param val
         * @returns
         */
        number(val) {
            return window.Skript.number.comma(val);
        },
    };
    return app.mount(selector);
};
